import { Component, OnInit } from '@angular/core';
import {FooterService } from "../service/footer.service"
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  copyYear: number;
  constructor(
    private svc: FooterService
  ) { }
 
  ngOnInit() {
    this.getVersion()
    this.copyYear  = new Date().getFullYear();
  }
  appversion: string;
  async getVersion() {
    this.svc.getDefaultVersion().subscribe((data: any) => {
      console.log("Version ", data.DefaultVersion);
      this.appversion = data.DefaultVersion

    })
    console.log("App Version", this.appversion);
  }
}
