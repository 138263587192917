import { Component, OnInit, Input } from '@angular/core';
import { OrderStatus, Inbox } from '../../../Models/approval-dashboard';
import { ProductStatus, ProductInfo } from '../../../Models/approval-dashboard';
import { Approval, OrderIdDetails, CommentsList, ApprovalPost, DashoardReportModel } from '../model/approval.model';
import { ApprovalDashboardService } from '../service/approval-dashboard.service';
import { CommonserviceService } from 'src/app/common/services/commonservice.service';
import { SelectItem } from 'primeng/api';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common'
import { isNumber, isNullOrUndefined } from 'util';
import { GlobalVariable } from '../../../common/globalvariable';
@Component({
  selector: 'app-approvaldashboard',
  templateUrl: './approvaldashboard.component.html',
  styleUrls: ['./approvaldashboard.component.scss']
})
export class ApprovaldashboardComponent implements OnInit {
  @Input()

  public CartId: number;
  @Input()
  public isBulk: number;
  orderid: string;
  orderclicked = true;
  comment: string;
  commentsList: CommentsList[];
  dashboardreport: DashoardReportModel = new DashoardReportModel();
  selectedValues: string[] = [];
  status: SelectItem[];
  notes = false;
  Workflow = false;
  Edit = false;
  approvallist: Approval[];
  orderIdDetails: OrderIdDetails[];
  itemid: number;
  action: string;
  role: string;
  cartId: number;
  //selectedOption = 1;
  timezone: string;
  cols: any[];
  selectedItems: string[] = [];
  defaultImage = GlobalVariable.Default_Url;
  checkeda = true;
  checkedb = true;
  checkedc = true;
  showWorkFlow() {
    this.Workflow = true;

  }
  showNotes() {
    this.notes = true;
  }
  onEdit(orderiddetails: OrderIdDetails) {
    orderiddetails.OnEdit = orderiddetails.OnEdit ? false : true;
    this.itemid = orderiddetails.ItemId;
  }
  onOpen() {
    this.Edit = false;
  }


  constructor(private approvaldashboardService: ApprovalDashboardService, private cmnsvc: CommonserviceService) {
    this.status = [
      { label: 'Pending', value: 'Pending' },
      { label: 'Approved', value: 'Approved' },
      { label: 'On Hold', value: 'On_Hold' },
      { label: 'Cancelled', value: 'Cancelled' }
    ];

  }


  ngOnInit() {
    this.checkeda = true;
    this.checkedb = true;
    this.checkedc = true;
    this.timezone = this.cmnsvc.selectedstorelocations.TimeZone;
    this.role = this.cmnsvc.UserDetails.Role;
    this.cols = [
      { field: 'OrderApprovalId', header: 'Store ID/Name' },
      { field: 'ModifiedDate', header: 'Order Placed Date/Time' },
      { field: 'TotalCost', header: 'Total Amount' },
      { field: 'submitter', header: 'Submitter' },
      { field: 'ApprovedBy', header: 'Approver' },
      //{ field: 'Status', header: 'Status' },
      { field: 'WorkflowHistory', header: 'Workflow History' },
      { field: 'Notes', header: 'Notes' },


    ];
    this.cmnsvc.locationChangeEmitter.subscribe((data: any) => {

      this.approvaldashboardService
        .getApprovalList(this.cmnsvc.UserDetails.UserId, this.cmnsvc.selectedstorelocations.StoreNumber)
        .subscribe((ApprovalList: any) => {
          this.approvallist = ApprovalList;
        });
    });
    if (this.cmnsvc.UserDetails) {
      if (!this.cmnsvc.isapprovaldashboardactive) {
        this.checkedc = true;
        if (!this.CartId) {
          this.approvaldashboardService
            .getApprovalList(this.cmnsvc.UserDetails.UserId, this.cmnsvc.selectedstorelocations.StoreNumber)
            .subscribe((data: any) => {
              this.approvallist = data;

            });
        }
        else {
          this.approvaldashboardService.getorderiddetails(this.CartId, this.isBulk).subscribe((data: any) => {

            this.approvallist = data;

            if (this.isBulk == 0) {
              this.checkedb = false;
            }
            if (this.isBulk == 1) {
              this.checkeda = false;
            }
          });
        }
      }
      else {
        this.checkedc = false;
        this.GetDashboardfromReport();
      }
    }
  }


  openGroup(list: Approval, i: number) {
    this.orderclicked = false;

    this.approvaldashboardService.getorderdetails(list.CartId, list.OrderId).subscribe((orderdetails: any) => {

      this.approvallist[i].orderIdDetails = orderdetails;
      _.each(orderdetails, (val: OrderIdDetails) => {
        val.OnEdit = false;
        val.OrderApprovalId = this.approvallist[i].OrderId;
        val.selectedOption = val.ItemCount;
        val._ItemCount = val.ItemCount;
      });
      this.orderIdDetails = orderdetails;

    });
  }

  async Save(approval: Approval, action: string) {
    let saveAppr: ApprovalPost[] = [];
    saveAppr = _.map(approval.orderIdDetails, (orderIdDetailsvalue) => {
      const appr = new ApprovalPost();
      appr.UserId = orderIdDetailsvalue.UserId;
      appr.Status = action;
      appr.CategoryId = orderIdDetailsvalue.CategoryId;
      appr.CartItemId = orderIdDetailsvalue.CartItemId;
      appr.StoreLocationId = orderIdDetailsvalue.StoreNumber;
      appr.Category = orderIdDetailsvalue.CategoryName;
      appr.FromEmailId = this.cmnsvc.selectedstorelocations.StoreUserEMail;
      appr.PreparedUser = orderIdDetailsvalue.UserName;
      appr.city = orderIdDetailsvalue.City;
      appr.StoreLocation = orderIdDetailsvalue.StoreNumber.toString();
      appr.OrderDate = this.cmnsvc.convertToTimezone(orderIdDetailsvalue.TimeZone);
      appr.StorePhone = orderIdDetailsvalue.Phone;
      appr.ItemName = orderIdDetailsvalue.ItemName;
      appr.Description = orderIdDetailsvalue.Description;
      appr.UnitOfMeasure = orderIdDetailsvalue.UnitOfMeasure;
      appr.ItemCount = orderIdDetailsvalue.ItemCount;
      appr.Price = orderIdDetailsvalue.Price;
      appr.BannerName = orderIdDetailsvalue.BannerName;
      appr.Description = orderIdDetailsvalue.Description;
      appr.StoreManagerId = orderIdDetailsvalue.UserId;
      appr.UserCartId = orderIdDetailsvalue.UserCartId;
      appr.ItemId = orderIdDetailsvalue.ItemId;
      appr.ItemCode = orderIdDetailsvalue.ItemCode;
      appr.ImageUrl = "";
      appr.GLAccountNo = orderIdDetailsvalue.GLAccountNo;
      appr.OrderId = this.orderIdDetails[0].OrderApprovalId
      appr.CategoryCode = orderIdDetailsvalue.CategoryCode;
      appr.VendorId = orderIdDetailsvalue.VendorId;
      appr.Region = orderIdDetailsvalue.RegionName;
      appr.Isbulkorder = orderIdDetailsvalue.Isbulkorder;
      appr.Nature = orderIdDetailsvalue.Nature;
      appr.UserName = this.cmnsvc.UserDetails.FirstName;
      return appr;
    });
    const Comments = await this.approvaldashboardService.getcoments(saveAppr[0].UserCartId).toPromise();
    this.approvaldashboardService.setapprovalstatus({ "value": saveAppr, "comments": Comments }).subscribe((approvalstatus: Approval[]) => {
      this.approvaldashboardService
        .getApprovalList(this.cmnsvc.UserDetails.UserId, this.cmnsvc.selectedstorelocations.StoreNumber)
        .subscribe((ApprovalList: any) => {
          this.approvallist = ApprovalList;
          this.cmnsvc.showToast('success', 'Success Message', 'Successfully Submitted');
        });
    });

  }
  iterate(n: number) {
    return Array.from(Array(n).keys(), (i) => i + 1);
  }
  onQuantitySelected(orderIdDetails) {
    orderIdDetails._ItemCount = orderIdDetails.ItemCount;
    orderIdDetails.ItemCount = orderIdDetails.selectedOption;
  }
  Cancel(orderIdDetails: OrderIdDetails) {
    orderIdDetails.OnEdit = false;
  }
  AddNote(CartId) {
    if (this.comment)
      this.comment = this.comment.trim();
    if (this.comment && this.comment.length > 0) {
      this.approvaldashboardService
        .insertcomments(this.cmnsvc.UserDetails.UserId, this.comment, CartId)
        .subscribe((reply: any) => {
          this.comment = '';
          this.cmnsvc.showToast('success', 'Success Message', 'Successfully Added Note');
        });
    }
    else {
      this.cmnsvc.showToast('warn', 'Warning Message', 'No Notes to Add');
    }
  }
  GetNote(CartId) {
    this.cartId = CartId;
    this.approvaldashboardService.getcoments(CartId).subscribe((coments: any) => {
      this.commentsList = coments;
      this.showNotes();
    });
  }
  deleteComments(CommentId) {
    this.approvaldashboardService.DeleteComments(CommentId).subscribe((data: any) => {
      if (data == true) {
        this.cmnsvc.showToast('success', 'Success Message', 'Deleted Successfully');
        this.GetNote(this.cartId);
      }
    });
  }
  approvalStatus(orderIdDetails: OrderIdDetails, status: string) {
    orderIdDetails.Status = status;
  }
  async Submit(orderIdDetails: OrderIdDetails[]) {
    let saveAppr: ApprovalPost[] = [];
    saveAppr = _.map(orderIdDetails, (orderIdDetailsvalue) => {
      const appr = new ApprovalPost();
      appr.UserId = orderIdDetailsvalue.UserId;
      appr.CategoryId = orderIdDetailsvalue.CategoryId;
      appr.Status = orderIdDetailsvalue.ApprvdStatus ? orderIdDetailsvalue.ApprvdStatus[0] : null;
      appr.CartItemId = orderIdDetailsvalue.CartItemId;
      appr.StoreLocationId = orderIdDetailsvalue.StoreNumber;
      appr.Category = orderIdDetailsvalue.CategoryName;
      appr.FromEmailId = this.cmnsvc.selectedstorelocations.StoreUserEMail;
      appr.PreparedUser = orderIdDetailsvalue.UserName;
      appr.city = orderIdDetailsvalue.City;
      appr.StoreLocation = orderIdDetailsvalue.StoreNumber.toString();
      appr.OrderDate = this.cmnsvc.convertToTimezone(orderIdDetailsvalue.TimeZone);
      appr.StorePhone = orderIdDetailsvalue.Phone;
      appr.ItemName = orderIdDetailsvalue.ItemName;
      appr.Description = orderIdDetailsvalue.Description;
      appr.UnitOfMeasure = orderIdDetailsvalue.UnitOfMeasure;
      appr.ItemCount = orderIdDetailsvalue.ItemCount;
      appr.Price = orderIdDetailsvalue.Price;
      appr.BannerName = orderIdDetailsvalue.BannerName;
      appr.StoreManagerId = orderIdDetailsvalue.UserId;
      appr.UserCartId = orderIdDetailsvalue.UserCartId;
      appr.ItemId = orderIdDetailsvalue.ItemId;
      appr.ItemCode = orderIdDetailsvalue.ItemCode;
      appr.ImageUrl = "";
      appr.GLAccountNo = orderIdDetailsvalue.GLAccountNo;
      appr.OrderId = orderIdDetailsvalue.OrderApprovalId;
      appr.CategoryCode = orderIdDetailsvalue.CategoryCode;
      appr.VendorId = orderIdDetailsvalue.VendorId;
      appr.Region = orderIdDetailsvalue.RegionName;
      appr.Isbulkorder = orderIdDetailsvalue.Isbulkorder;
      appr.Nature = orderIdDetailsvalue.Nature;
      appr.UserName = this.cmnsvc.UserDetails.FirstName;
      return appr;
    });
    const value = saveAppr.filter(a => a.Status != null || a.Status != undefined);
    if (value && value.length > 0) {
      const Comments = await this.approvaldashboardService.getcoments(value[0].UserCartId).toPromise();
      this.approvaldashboardService.setapprovalstatus({ "value": value, "comments": Comments }).subscribe((data: Approval[]) => {
        this.approvaldashboardService
          .getApprovalList(this.cmnsvc.UserDetails.UserId, this.cmnsvc.selectedstorelocations.StoreNumber)
          .subscribe((ApprovalList: any) => {
            this.approvallist = ApprovalList;
            this.cmnsvc.showToast('success', 'Success Message', 'Successfully Submitted');
          });
      });
    } else {
      this.cmnsvc.showToast('warn', 'Warning Message', 'No Action Selected');
    }
  }

  updateUrl(products: OrderIdDetails) {
    products.ImageUrl = 'assets/img/no-image.jpg';
  }
  handle(orderIdDetails: OrderIdDetails) {

    if (orderIdDetails.ApprvdStatus.length == 2) {
      orderIdDetails.ApprvdStatus[0] = orderIdDetails.ApprvdStatus[1];
      orderIdDetails.ApprvdStatus.splice(1, 1);

    }
  }
  onReset(orderIdDetails: OrderIdDetails) {

    orderIdDetails.ItemCount = orderIdDetails._ItemCount;
    orderIdDetails.selectedOption = 1;
    orderIdDetails.OnEdit = false;
  }
  overrideapproval() {
    this.approvaldashboardService.GetOrderIdApprDetails(this.orderid).subscribe((data: any) => {
      this.approvallist = data;
    });
  }
  GetDashboardfromReport() {
    this.dashboardreport.FiscalYear = this.cmnsvc.reportfilter.FiscalYear;
    this.dashboardreport.Banner = this.cmnsvc.reportfilter.Banner;
    this.dashboardreport.BdgtAllctdFrom = this.cmnsvc.reportfilter.BdgtAllctdFrom;
    this.dashboardreport.BdgtAllctdTo = this.cmnsvc.reportfilter.BdgtAllctdTo;
    this.dashboardreport.BdgtCnsmdFrom = this.cmnsvc.reportfilter.BdgtCnsmdFrom;
    this.dashboardreport.BdgtCnsmdTo = this.cmnsvc.reportfilter.BdgtCnsmdTo;
    this.dashboardreport.Period = this.cmnsvc.reportfilter.Period;
    this.dashboardreport.Banner = this.cmnsvc.reportfilter.Banner;
    this.dashboardreport.Region = this.cmnsvc.reportfilter.Region;
    this.dashboardreport.ItemCode = this.cmnsvc.reportfilter.ItemCode;
    this.dashboardreport.WDCode = this.cmnsvc.reportfilter.WDCode;
    this.dashboardreport.Store = this.cmnsvc.reportfilter.Store;
    this.dashboardreport.Status = this.cmnsvc.reportfilter.Status;
    this.dashboardreport.UserId = this.cmnsvc.UserDetails.UserId;
    this.approvaldashboardService.GetDashboardfromReport(this.dashboardreport).subscribe((data: any) => {
      this.approvallist = data;

    })
  }
}
