import { Injectable } from '@angular/core';
import { DalbaseService } from 'src/app/common/dalbase.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FooterService extends DalbaseService {

  constructor(private httpclient: HttpClient) {
    super(httpclient);
  }

  getDefaultVersion() {
    return this.executeGet('Homepage/GetVersion');
  }
}
